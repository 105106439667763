import { Box, Button, SvgIcon, Typography } from "@mui/material";
import CommentIcon from "@mui/icons-material/Comment";

import "./DocumentNav.scss";
import { InvestmentMemo } from "../../../../models/InvestmentMemo";

type Props = {
  investmentMemo: InvestmentMemo;
  summarized?: boolean;
  readonly: boolean;
  handleExportToDocx: () => void;
  handleCompleteReview: () => void;
  handleNavigateTo: (id: string) => void;
};
const DocumentNav: React.FC<Props> = (props) => {
  const { investmentMemo, summarized, readonly, handleNavigateTo, handleCompleteReview } =
    props;
  return (
    <Box className="investment__memo--document-nav">
      <Box className="document-nav-container">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            borderBottom: "1px solid #f1f2f2",
          }}
        >
          <Button
            variant="text"
            onClick={() => handleCompleteReview()}
            disabled={summarized || readonly}
            sx={{
              fontSize: "12px",
              flex: 1,
              m: "10px 15px 15px 0px",
              fontWeight: 600,
              color: "#c41e3a",
              border: "1px solid #c41e3a",
            }}
          >
            {!summarized ? 'Complete review' : 'Pitch to our investors'}
          </Button>
        </Box>

        <Typography sx={{ m: "0px 0 10px 0", fontWeight: 600 }}>
          Navigation
        </Typography>
        {investmentMemo.sections.filter(
          (section) =>
            section.blocks.filter((b) => b.how_to_fill).length !== 0).sort((a, b) => a.order - b.order).map((section, index) => {
              return (
                <Box key={index} className="document-nav-section-container">
                  <Typography
                    className="document-nav-item"
                    onClick={() => handleNavigateTo(section.id)}
                  >
                    {section.title}
                  </Typography>

                  {section.blocks.filter((item) => item.how_to_fill).sort((a, b) => a.order - b.order).map((block, index) => {
                    const hasComments = block.ai_response?.answers.some(
                      (item) => !item || item.length === 0
                    );
                    return (
                      <Box
                        key={index}
                        className="document-nav-block-container"
                        onClick={() => handleNavigateTo(block.id)}
                      >
                        <Typography className="document-nav-item">
                          {block.title}
                        </Typography>
                        {!summarized && (
                          <Box sx={{ mr: '35px' }}>
                            {hasComments && (
                              <SvgIcon className="document-nav-item-icon">
                                <CommentIcon />
                              </SvgIcon>
                            )}
                          </Box>
                        )}
                      </Box>
                    );
                  })}
                </Box>
              );
            })}
      </Box>
    </Box>
  );
};

export { DocumentNav };
