// Import react-circular-progressbar module and styles
import {
    CircularProgressbar,
    buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export const Progress: React.FC<{ value: number, label: string }> = (props) => {
    const { value, label } = props;
    return <>
        <CircularProgressbar
            value={value}
            text={label}
            styles={buildStyles({
                textColor: "#24da78",
                pathColor: "#24da78",
                textSize: "28px",
            })}
        />
    </>
};