import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import * as animationData from '../../assets/lotties/loading.json';
import Lottie from 'react-lottie';
import { Progress } from './Progress/Progress';
import { Box } from '@mui/material';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#24da78',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#24da78',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
    ({ theme, ownerState }) => ({
        color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
        ...(ownerState.active && {
            color: '#24da78',
        }),
        '& .QontoStepIcon-completedIcon': {
            color: '#24da78',
            zIndex: 1,
            fontSize: 18,
        },
        '& .QontoStepIcon-circle': {
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
        },
    }),
);

type StepIconCustomProps = {
    progressValue: number,
    progressLabel: string,
    loading: boolean,
} & StepIconProps;
function QontoStepIcon(props: StepIconCustomProps) {
    const { active, completed, tabIndex, progressValue, progressLabel, loading, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                loading ? (
                    <Lottie options={defaultOptions}
                        height={40}
                        width={40}
                        isStopped={false}
                        isPaused={false} />
                ) : ((tabIndex === 1 && active)
                    ? (<div>
                        <Box sx={{ width: '40px', padding: '0 10px', background: '#ffffff', zIndex: 22, position: 'relative' }}>
                            <Progress value={progressValue} label={progressLabel} />
                        </Box>
                    </div>)
                    : (<div className="QontoStepIcon-circle" />)
                )
            )}
        </QontoStepIconRoot>
    );
}

const steps = ['Fill the application form', 'Complete your investment memo', 'Feedback from our AI analyst', 'Pitch to our investors'];

export const CustomizedSteppers: React.FC<{ value: number, progressValue: number, progressLabel: string, handleStepChange: (value: number) => void }> = (props) => {
    const loading = props.value % 10 === 0;
    const activeStep = props.value % 10 === 0 ? props.value / 10 : props.value;

    return (
        <Stack sx={{ width: '100%' }} spacing={4}>
            <Stepper alternativeLabel activeStep={activeStep - 1} connector={<QontoConnector />}>
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel
                            onClick={() => {
                                if (index > 0)
                                    props.handleStepChange(index + 1)
                            }}
                            StepIconComponent={() => <QontoStepIcon
                                completed={(index + 1) < activeStep}
                                loading={(loading && index === activeStep - 1)}
                                progressValue={props.progressValue}
                                progressLabel={props.progressLabel}
                                tabIndex={index}
                                active={index === activeStep - 1}
                                icon />}>
                            {label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Stack>
    );
}