import { LinearProgress } from "@mui/material"

const Loading: React.FC = () => {
    return (
        <LinearProgress color="success" sx={{
            position: 'fixed',
            width: '100%',
            top: '90px',
            zIndex: 90000,
            left: '0px'
        }} />
    )
};

export { Loading };