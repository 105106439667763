import { Box, Typography } from "@mui/material";
import Styled from "./InvestmentMemo.styled";
import React, { useEffect, useRef, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { InvestmentMemoResponse } from "../../models/InvestmentMemo";
import { Placeholder } from "./components/Placeholder";
import { marked } from "marked";
import { CommentsThread } from "./components/CommentsThread/CommentsThread";
import { ApplicationMetadata } from "../../models/ApplicationMetadata";
import { DocumentNav } from "./components/DocumentNav/DocumentNav";
import { Loading } from "../../components/Loading/Loading";
import { SectionEvaluations } from "../../models/Evaluation";
import { EvaluationPanel } from "./components/EvaluationPanel/EvaluationPanel";
import { EvaluationQuestionsThread } from "./components/EvaluationQuestionsThread/EvaluationQuestionsThread";

const InvestmentMemo: React.FC<{
  applicationMetadata: ApplicationMetadata;
  readonly: boolean;
  onQuestionAnswered: () => void;
  summarized?: boolean;
}> = (props) => {
  const { applicationMetadata, summarized, readonly, onQuestionAnswered } = props;

  const [sectionEvaluations, setSectionEvaluations] = useState<SectionEvaluations>({});
  const [isSubmitting, setIsSubmitting] = useState<boolean>();
  const [memoTemplate, setMemoTemplate] = useState<InvestmentMemoResponse>();
  const [comments, setComments] = useState<{
    blockId: string;
    index: number;
    question: string;
  } | null>(null);
  const [resolvedComments, setResolvedComments] = useState<
    { blockId: string; index: number }[]
  >([]);
  const [evaluationQuestions, setEvaluationQuestions] = useState<{
    sectionId: string;
    questionIndex: number;
    question: string;
  } | null>(null);
  const [resolvedEvaluationQuestions, setResolvedEvaluationQuestions] = useState<{
    sectionId: string;
    questionIndex: number;
  }[]>([]);
  const refs = useRef<{ [id: string]: HTMLDivElement | null }>({});

  useEffect(() => {
    // fetch application memo:
    const fetch = async () => {
      setIsSubmitting(true);
      axios
        .get<any, AxiosResponse<InvestmentMemoResponse>>(
          process.env.REACT_APP_API_URL +
          "/investmentmemo/" +
          applicationMetadata.rowKey
        )
        .then((response) => {
          setMemoTemplate(response.data);
          onQuestionAnswered();
        })
        .finally(() => {
          setIsSubmitting(false);
        });

      if (summarized) {
        axios
          .get<any, AxiosResponse<SectionEvaluations>>(
            `${process.env.REACT_APP_API_URL}/investmentmemo/${applicationMetadata.rowKey}/evaluation`
          )
          .then((response) => {
            setSectionEvaluations(response.data);
            onQuestionAnswered();
          })
          .catch((error) => {
            //
          });
      }
    };

    fetch();
  }, [applicationMetadata.rowKey, resolvedComments, resolvedEvaluationQuestions, summarized, onQuestionAnswered]);

  const onResolveComment = (blockId: string, index: number) => {
    setResolvedComments([...resolvedComments, { blockId, index }]);
  };

  const onResolveEvaluationQuestion = (sectionId: string, questionIndex: number) => {
    setResolvedEvaluationQuestions([...resolvedEvaluationQuestions, { sectionId, questionIndex }]);
  };

  const handleNavigateTo = (id: string) => {
    refs.current[id]?.scrollIntoView({ behavior: "smooth", inline: "start" });
  };

  const handleCompleteReview = () => {
    setIsSubmitting(true);
    axios
      .post<any, AxiosResponse<InvestmentMemoResponse>>(
        process.env.REACT_APP_API_URL +
        "/investmentmemo/" +
        applicationMetadata.rowKey
      )
      .then((response) => { })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  /*
    const handleExportToDocx = () => {
        const element = document.getElementsByClassName("page A4")[0];
        const html = `<div style="text-align: justify;">${element.outerHTML}</div>`;

        asBlob(html).then((docxContent) => {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(docxContent as Blob);
            link.download = "document.docx";
            link.click();
        });
    };
    */

  return (
    <Styled container className="investment__memo">
      {isSubmitting && <Loading />}
      <Box className="investment__memo--side-panel">
        <>
          {comments && (
            <Box className="investment__memo--comments">
              <CommentsThread
                applicationId={applicationMetadata.rowKey}
                blockId={comments.blockId}
                index={comments.index}
                question={comments.question}
                resolvedComments={resolvedComments}
                onClose={() => {
                  setComments(null);
                }}
                onResolve={() =>
                  onResolveComment(comments.blockId, comments.index)
                }
              />
            </Box>
          )}
          {evaluationQuestions && (
            <Box className="investment__memo--comments">
              <EvaluationQuestionsThread
                applicationId={applicationMetadata.rowKey}
                sectionId={evaluationQuestions.sectionId}
                questionIndex={evaluationQuestions.questionIndex}
                question={evaluationQuestions.question}
                resolvedEvaluationQuestions={resolvedEvaluationQuestions}
                onClose={() => {
                  setEvaluationQuestions(null);
                }}
                onResolve={() =>
                  onResolveEvaluationQuestion(evaluationQuestions.sectionId, evaluationQuestions.questionIndex)
                }
              />
            </Box>
          )}
        </>
      </Box>

      {memoTemplate?.data.setup_memo_templates[0] && (
        <DocumentNav
          summarized={summarized}
          readonly={readonly}
          handleCompleteReview={handleCompleteReview}
          handleNavigateTo={handleNavigateTo}
          handleExportToDocx={() => { }}
          investmentMemo={memoTemplate?.data.setup_memo_templates[0]}
        />
      )}

      <Box className="investment__memo--content">
        {memoTemplate && (
          <Box className="page A4">
            <Typography className="title">
              {applicationMetadata.startupName}
            </Typography>
            {memoTemplate?.data.setup_memo_templates[0].sections
              .filter(
                (section) =>
                  section.blocks.filter((b) => b.how_to_fill).length !== 0
              )
              .sort((a, b) => a.order - b.order)
              .map((section) => (
                <div key={section.id} ref={(el) => (refs.current[section.id] = el)}>
                  <h1>{section.title}</h1>
                  {section.blocks
                    .filter((item) => item.how_to_fill)
                    .sort((a, b) => a.order - b.order)
                    .map((block) => {
                      const questions = block.how_to_fill
                        .split(/\d+\./)
                        .map((item) => item.trim())
                        .filter((item) => item);
                      return (
                        <div
                          key={block.id}
                          ref={(el) => (refs.current[block.id] = el)}
                        >
                          <h2>{block.title}</h2>
                          {block.ai_response &&
                            block.ai_response[
                              summarized ? "summarizedAnswers" : "answers"
                            ].map((answer, index) => {
                              if (!answer || answer.length === 0) {
                                if (summarized) return null;
                                return (
                                  <span key={index}>
                                    <Placeholder
                                      readonly={readonly}
                                      onClick={() => {
                                        if (readonly) return;

                                        if (
                                          !comments ||
                                          comments?.blockId !== block.id ||
                                          comments?.index !== index
                                        )
                                          setComments({
                                            blockId: block.id,
                                            index,
                                            question: questions[index],
                                          });
                                        else setComments(null);
                                      }}
                                      label={questions[index]}
                                      focused={
                                        comments?.blockId === block.id &&
                                        comments?.index === index
                                      }
                                    />
                                  </span>
                                );
                              }

                              const response: string = marked.parse(
                                answer
                              ) as string;
                              return (
                                <span
                                  key={index}
                                  dangerouslySetInnerHTML={{ __html: response }}
                                ></span>
                              );
                            })}
                        </div>
                      );
                    })}

                  {summarized && (
                    <EvaluationPanel
                      sectionId={section.id}
                      evaluation={sectionEvaluations[section.id]}
                      onChatButtonClicked={(sectionId, questionIndex) => {
                        if (readonly) {
                          return;
                        }

                        if (!evaluationQuestions ||
                            evaluationQuestions.sectionId !== sectionId ||
                            evaluationQuestions.questionIndex !== questionIndex) {
                              setEvaluationQuestions({
                                sectionId,
                                questionIndex,
                                question: sectionEvaluations[section.id].questions[questionIndex]
                              });
                        } else {
                          setEvaluationQuestions(null);
                        }
                      }}
                    />
                  )}
                </div>
              ))}
          </Box>
        )}
      </Box>
    </Styled>
  );
};

export { InvestmentMemo };
