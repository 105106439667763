import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Home } from './views/Home/Home';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "react-chat-elements/dist/main.css";


const router = createBrowserRouter([
  {
    path: "application/:applicationId",
    element: (
      <App />
    ),
  },
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "*",
    element: <Home />,
  }
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode><RouterProvider router={router} /></React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
