import { styled } from '@mui/material';
import Placeholder from './Placeholder';

const Styled = styled(Placeholder)`
    &__placeholder {
        display: flex;
        flex-direction: row;

        > .placeholder {
            display: flex;
            align-items: center;
            justify-content: center;
            align-content: center;
            background: #da242430;
            padding: 0 16px;
            height: 2px;
            width: 100%;
            margin: 24px 0;

            border-radius: 8px;
            border: 1px #da2424;
            border-radius: 8px;
            color: #000000;
            cursor: pointer;

            &:hover {
                background: #da242460;
            }

            &.focused {
                background: #da242490;
            }


            > .placeholder__icon {
                font-size: 20px;
                color: #c41e3a;
                margin: 0 0 0 0;
                border: 1px solid #c41e3a70;
                border-radius: 50%;
                padding: 6px;
                background: white;
            }
        }
    }
`;

export default Styled;