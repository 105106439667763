import { Box, Button, Container, FormControl, InputLabel, TextField } from "@mui/material";
import { ChangeEvent, useState } from "react";
import ResponsiveAppBar from "../../components/AppBar/AppBar";
import { useNavigate } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import { ApplicationMetadata } from "../../models/ApplicationMetadata";

const Home: React.FC = () => {
    const [appId, setAppId] = useState<string>('');
    const [error, setError] = useState<boolean>();
    const navigate = useNavigate();

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value } = event.target;
        setAppId(value);
        setError(false);
    };

    const handleSubmit = () => {
        axios.get<any, AxiosResponse<ApplicationMetadata>>(process.env.REACT_APP_API_URL + '/investmentapplication?applicationId=' + appId)
            .then(() => {
                navigate(`/application/${appId}`);

            })
            .catch(() => {
                setError(true);
            });
    };

    return <>
        <div className="App">
            <ResponsiveAppBar />
            <div className='App-body'>
                <Container maxWidth="lg" sx={{ paddingTop: '24px', marginTop: '24px', width: '380px' }} className='App-content-investment-memo'>
                    <Container maxWidth="md">
                        <Box

                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                                marginTop: '125px',
                            }}
                        >
                            <FormControl variant="standard">
                                <InputLabel shrink>
                                    Your application ID
                                </InputLabel>
                                <TextField
                                    size='small'
                                    variant="outlined"
                                    name='startupName'
                                    value={appId}
                                    fullWidth
                                    onChange={handleChange}
                                    required
                                    error={error}
                                    helperText={error ? "Incorrect entry." : ""}
                                />
                            </FormControl>

                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button onClick={handleSubmit} type='submit' size='small' variant="contained" sx={{ width: '100%' }} color="primary">Submit</Button>
                            </Box>
                        </Box>
                    </Container>
                </Container>
            </div>
        </div >
    </>
};

export { Home };