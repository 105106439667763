import { Grid, styled } from '@mui/material';

const Styled = styled(Grid)`
  background: white;
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  height: 100%;
  padding: 16px 0px 0px 0px;

  > .investment__memo--content {
    display: flex;
    flex: 1;
    justify-content: center;
    background: #ffffff;
    height: 100%;
    min-width: 840px;
    overflow: scroll;
    overflow: hidden;
  }

  > .investment__memo--side-panel {
    display: flex;
    position: fixed;
    top: 100px;
    right: 20px;
    z-index: 3;

    > .investment__memo--progress {
      width: 70px;
      margin: 10px 24px;
    }

    > .investment__memo--comments {
      width: 320px;
      box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.3);
      background: #ffffff;
      border-radius: 10px;
      z-index: 2;
      padding: 0 10px 0 5px;
    }
  }

  .page {
    background: white;
    display: block;
    margin-bottom: 0.5cm;
    box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.3);
    font-family: "lato", sans-serif;
    font-size: 12pt; /* Standard size for A4 readability */
    line-height: 140%; /* Adjusts spacing for readability */
    text-align: justify;

    margin: 16px;
    overflow: auto;
    overflow-y: scroll;

    &.A4 {
      width: 21cm;
      /* height: 29.7cm; */

      display: flex;
      flex-direction: column;
      padding: 0.7in;
      margin-top: 15px;
      margin-bottom: 0;
    }

    /* Title */
    .title {
        font-family: "lato", sans-serif;
        font-size: 26pt; /* Larger size for the main title */
        font-weight: bold!important;
        text-align: center; /* Centered to stand out */
        padding-bottom: 40px;
        padding-top: 40px;
    }
    
    /* H1 Heading */
    h1 {
        font-size: 20pt; /* Larger than body text, readable */
        font-weight: bold;
        text-align: left; /* Starts from the left */
        padding-top: 20px;
        padding-bottom: 20px;
    }
    
    /* H2 Heading */
    h2 {
        font-size: 16pt; /* Slightly smaller than h1, still prominent */
        font-weight: bold;
        text-align: left; /* Starts from the left */
        padding-top: 10px;
        padding-bottom: 10px;
    }

  }

  @media print {
    body,
    .page {
      margin: 0;
      box-shadow: 0;
    }
  }
`;

export default Styled;
