import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as signalR from '@microsoft/signalr';
import axios, { AxiosResponse } from 'axios';

import { Container } from '@mui/material';
// import React components:
import { CustomizedSteppers } from './components/ProgressStepper/ProgressStepper';
import { InvestmentMemo } from './views/InvestmentMemo/InvestmentMemo';
import ResponsiveAppBar from './components/AppBar/AppBar';
import ApplicationForm from './views/ApplicationForm/ApplicationForm';
// import models:
import { ApplicationMetadata } from './models/ApplicationMetadata';

import './App.scss';
import { EmptyAiResponseQuestion } from './models/EmptyAiResponseQuestion';
import { InvestmentMemoResponse } from './models/InvestmentMemo';

function App() {
  const { applicationId } = useParams();
  const [connection, setConnection] = useState<signalR.HubConnection | null>(null);
  const [applicationMetadata, setApplicationMetadata] = useState<ApplicationMetadata | null>(null);
  const [updateDate, setUpdateDate] = useState<number>(Date.now());
  const [emptyAIResponseQuestions, setEmptyAiResponseQuestions] = useState<EmptyAiResponseQuestion[]>([]);
  const [navigationPhaseValue, setNavigationPhaseValue] = useState<number | null>();
  const [totalQuestions, setTotalQuestions] = useState(0);

  const onQuestionAnswered = useCallback(() => {
    axios.get<any, AxiosResponse<EmptyAiResponseQuestion[]>>(process.env.REACT_APP_API_URL + '/EmptyAiResponseQuestion/' + applicationId)
      .then((response) => {
        setEmptyAiResponseQuestions(response.data);
      })
      .catch(() => { });
  }, [applicationId]);

  useEffect(() => {
    // fetch application metadata:
    const fetch = async () => {
      axios.get<any, AxiosResponse<ApplicationMetadata>>(process.env.REACT_APP_API_URL + '/investmentapplication?applicationId=' + applicationId)
        .then((response) => {
          setApplicationMetadata(response.data);
          onQuestionAnswered();
        })
        .catch((error) => {
          //
        });

      axios
        .get<any, AxiosResponse<InvestmentMemoResponse>>(
          process.env.REACT_APP_API_URL +
          "/investmentmemo/" +
          applicationId
        )
        .then((response) => {
          const memoTemplate = response.data;
          const questions = memoTemplate?.data.setup_memo_templates[0].sections
            .filter((s) => {
              return s.blocks.filter((b) => b.how_to_fill).length !== 0
            })
            .flatMap((s) => {
              return s.blocks
                .filter((b) => b.how_to_fill)
                .flatMap((b) => {
                  return b.how_to_fill
                    .split(/\d+\./)
                    .map((str) => str.trim())
                    .filter((str) => str);
                })
            });
          setTotalQuestions(questions.length);
        })
        .catch((error) => {
          //
        });
    }

    fetch();
  }, [applicationId, updateDate, onQuestionAnswered]);

  useEffect(() => {
    // Function to start the SignalR connection
    const connectSignalR = async () => {
      try {
        // Fetch the connection info from your Azure Function (the negotiation endpoint)
        const response = await fetch(process.env.REACT_APP_API_URL + '/signalr/negotiate', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: applicationId
          })
        });
        const connectionInfo = await response.json();

        // Create the connection with the Azure SignalR Service URL and Access Token
        const newConnection = new signalR.HubConnectionBuilder()
          .withUrl(connectionInfo.url, {
            accessTokenFactory: () => connectionInfo.accessToken
          })
          .withAutomaticReconnect()
          .build();

        // Set up event handlers for receiving messages
        newConnection.on('newMessage', (message) => {
          setUpdateDate(Date.now());
        });

        // Start the connection
        await newConnection.start();
        console.log('SignalR Connected');

        setConnection(newConnection);
      } catch (error) {
        console.error('Error establishing SignalR connection:', error);
      }
    };

    connectSignalR();

    // Clean up the connection on unmount
    return () => {
      if (connection) {
        connection.stop();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getContent = () => {
    if (!applicationMetadata) return null;
    const phase = navigationPhaseValue ?? applicationMetadata.phase;
    switch (phase) {
      case 1:
        return <ApplicationForm applicationId={applicationMetadata.rowKey} />;

      case 2:
        return <InvestmentMemo
          readonly={Boolean(navigationPhaseValue) && navigationPhaseValue !== applicationMetadata.phase}
          applicationMetadata={applicationMetadata}
          onQuestionAnswered={onQuestionAnswered} />;
      case 3:
        return <InvestmentMemo
          readonly={Boolean(navigationPhaseValue) && navigationPhaseValue !== applicationMetadata.phase}
          applicationMetadata={applicationMetadata}
          summarized
          onQuestionAnswered={onQuestionAnswered} />;
    }
  };

  if (!applicationMetadata)
    return null;

  const handleStepChange = (value: number) => {
    setNavigationPhaseValue(value);
  };

  const progressLabel = !emptyAIResponseQuestions.length || !totalQuestions
    ? "0\\0"
    : `${totalQuestions - emptyAIResponseQuestions.filter(q => !q.answeredByUser).length}\\${totalQuestions}`;

  const progressValue = !emptyAIResponseQuestions.length || !totalQuestions
    ? 100
    : (totalQuestions - emptyAIResponseQuestions.filter(q => !q.answeredByUser).length) * 100 / totalQuestions;

  return (
    <div className="App">
      <ResponsiveAppBar />
      <div className='App-body'>
        <Container maxWidth="sm" className='App-content-stepper'>
          <CustomizedSteppers
            value={applicationMetadata.phase}
            progressValue={progressValue}
            handleStepChange={handleStepChange}
            progressLabel={progressLabel} />
        </Container>

        <Container maxWidth="lg" sx={{ paddingTop: '24px', marginTop: '24px' }} className='App-content-investment-memo'>
          {getContent()}
        </Container>
      </div>
    </div>
  );
}

export default App;
