import { Box, styled, SvgIcon, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import CommentIcon from '@mui/icons-material/Comment';

type Props = {
    label: string,
    focused: boolean,
    readonly: boolean,
    className?: string,
    icon?: React.ReactNode,
    onClick?: () => void,
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#ffffff',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: '11px',
        border: '1px solid #da242480',
    },
}));

const Placeholder: React.FC<Props> = (props) => {
    const { label, focused, icon, className, onClick } = props;

    return (
        <Box className={`${className}__placeholder`} onClick={onClick}>
            <HtmlTooltip
                arrow
                placement="top"
                title={
                    <>
                        <Typography sx={{ fontSize: '12px', padding: '4px 10px' }}>{label}</Typography>
                    </>
                }
            >
                <Box className={`placeholder ${focused ? 'focused' : 'normal'}`}>
                    <SvgIcon className="placeholder__icon">{icon}</SvgIcon>
                </Box>
            </HtmlTooltip>
        </Box>
    );
};

Placeholder.defaultProps = {
    icon: <CommentIcon />
};

export default Placeholder;
