import React from "react";
import {
  Box,
  Typography,
  Rating,
  List,
  ListItem,
  Alert,
  Stack,
  Paper,
  SvgIcon,
  IconButton,
} from "@mui/material";
import CommentIcon from "@mui/icons-material/Comment";

import { marked } from "marked"

import { Evaluation } from "../../../../models/Evaluation";

type ListBoxContentProps = { children: React.ReactNode[] };

type EvaluationPanelProps = {
  sectionId: string;
  evaluation: Evaluation;
  onChatButtonClicked: (sectionId: string, questionIndex: number) => void;
};

const ListBoxContent: React.FC<ListBoxContentProps> = ({ children }) => (
  <Paper
    variant="outlined"
    tabIndex={0}
    sx={{
      width: "100%",
      padding: "16px 14px 16px 14px",
      "&:focus-within": {
        borderColor: "rgba(25, 118, 210, 0.87)",
      },
    }}
  >
    <Stack
      component={List}
      gap={0.5}
      disablePadding
      sx={{
        listStyleType: "disc",
        pl: 2,
      }}
    >
      {children}
    </Stack>
  </Paper>
);

export const EvaluationPanel: React.FC<EvaluationPanelProps> = ({
  sectionId,
  evaluation,
  onChatButtonClicked
}) => {
  if (!evaluation) {
    return null;
  }

  return (
    <Box sx={{ margin: '20px', marginRight: '50px' }}>
      <Alert severity="info" sx={{ mb: 2, width: "100%" }}>
        <Typography variant="body1" fontWeight="bold">
          Evaluation criteria
        </Typography>
        <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
          {evaluation.evaluationCriteriaText}
        </Typography>
      </Alert>

      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="h6">Rating:</Typography>
        <Rating tabIndex={0} value={evaluation.rating} readOnly />
      </Box>

      <Box sx={{ marginBottom: 2 }}>
        <Typography sx={{ fontSize: '16px' }}>PROs:</Typography>
        <ListBoxContent>
          {evaluation.pros.map((pro, index) => (
            <ListItem key={index} sx={{ display: "list-item", padding: 0 }}>
              <Typography
                sx={{
                  margin: 0,
                  fontSize: '14px'
                }}
              >
                {pro}
              </Typography>
            </ListItem>
          ))}
        </ListBoxContent>
      </Box>

      <Box sx={{ marginBottom: 2 }}>
        <Typography sx={{ fontSize: '16px' }}>CONs:</Typography>
        <ListBoxContent>
          {evaluation.cons.map((con, index) => (
            <ListItem key={index} sx={{ display: "list-item", padding: 0 }}>
              <Typography
                sx={{
                  margin: 0,
                  fontSize: '14px'
                }}
              >
                {con}
              </Typography>
            </ListItem>
          ))}
        </ListBoxContent>
      </Box>

      <Box>
        <Typography sx={{ fontSize: '16px' }}>Questions:</Typography>
        <ListBoxContent>
          {evaluation.questions.map((q, index) => (
            <ListItem key={index} sx={{ display: "list-item", padding: 0 }}>
              <span style={{ whiteSpace: "pre-wrap", fontSize: '14px' }}>
                {q}
                {evaluation.answers[index]
                  ? <List disablePadding sx={{ listStyleType: 'circle', pl: 2, }}>
                    <ListItem key={index} sx={{ display: "list-item", padding: 0 }}>
                      <span dangerouslySetInnerHTML={{ __html: (marked.parse(evaluation.answers[index]) as string).replaceAll('<p>', '').replaceAll('</p>', '') }} />
                    </ListItem>
                  </List>
                  : <IconButton
                    onClick={() => onChatButtonClicked(sectionId, index)}
                    sx={{
                      verticalAlign: "text-bottom",
                      marginLeft: "4px",
                      padding: 0,
                    }}
                  >
                    <SvgIcon
                      sx={{
                        color: "#c41e3a",
                        fontSize: "14px",
                      }}
                    >
                      <CommentIcon />
                    </SvgIcon>
                  </IconButton>}
              </span>
            </ListItem>
          ))}
        </ListBoxContent>
      </Box>
    </Box>
  );
};
