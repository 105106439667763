import React, { ChangeEvent, FormEvent, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import {
    TextField,
    Button,
    Container,
    Box,
    InputLabel,
    FormControl,
    Input
} from '@mui/material';

import FileUploadIcon from '@mui/icons-material/FileUpload';

import './ApplicationForm.scss';
import { Loading } from '../../components/Loading/Loading';

type FormDataType = {
    startupName: string,
    file: File | null,
}

type Props = {
    applicationId: string,
};

const ApplicationForm: React.FC<Props> = (props) => {
    const { applicationId } = props;

    const [isSubmitting, setIsSubmitting] = useState<boolean>();
    const [formData, setFormData] = useState<FormDataType>({
        startupName: '',
        file: null,
    });

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value, type } = event.target;

        if (type === 'checkbox') {
            const target = event.target as HTMLInputElement;
            setFormData({
                ...formData,
                [name]: target.checked,
            });
        } else if (type === 'file') {
            const target = event.target as HTMLInputElement;
            const file = target.files ? target.files[0] : null;
            setFormData({
                ...formData,
                file: file,
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            setIsSubmitting(true);
            const data = new FormData();
            data.append('startupName', formData.startupName);

            if (formData.file) {
                data.append('file', formData.file);
            }

            axios.post<any, AxiosResponse<string>>(process.env.REACT_APP_API_URL + '/pitchdeck/' + applicationId, data, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Required for file uploads
                },
            }).then((response) => {

            });
        } catch (error) {
            // Handle error response
            console.error('Submit application failed:', error);
            setIsSubmitting(false);
        }
    };

    return (
        <Container maxWidth="sm">
            {isSubmitting && <Loading />}
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    marginTop: 4,
                }}
            >
                <FormControl variant="standard">
                    <InputLabel shrink>
                        Startup name
                    </InputLabel>
                    <TextField
                        size='small'
                        variant="outlined"
                        name='startupName'
                        value={formData.startupName}
                        fullWidth
                        onChange={handleChange}
                        disabled={isSubmitting}
                        required
                    />
                </FormControl>



                <FormControl variant="standard">
                    <InputLabel shrink>
                        Pitch deck
                    </InputLabel>
                    <label className="upload-button">
                        {formData.file
                            ? <>
                                <span>{formData.file.name}</span>
                            </>
                            : <>
                                <FileUploadIcon style={{ marginRight: '8px', fontSize: '20px' }} />
                                <span>Click to Upload (PDF only)</span>
                            </>}
                        <Input
                            type="file"
                            onChange={handleChange}
                            inputProps={{ accept: 'application/pdf' }}
                            disabled={isSubmitting}
                        />
                    </label>
                </FormControl>


                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button disabled={true} size='small' variant="outlined" sx={{ mr: '4px', width: '160px' }}>Save for later</Button>
                    <Button disabled={isSubmitting} type='submit' size='small' variant="contained" sx={{ ml: '4px', width: '160px' }} color="primary">Apply</Button>
                </Box>
            </Box>
        </Container>
    );
};

export default ApplicationForm;
