import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Button } from "react-chat-elements";
import { format } from "date-fns";
import Avatar from "react-avatar";
import { marked } from "marked";
import Lottie from 'react-lottie';
import { Box } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import * as animationData from '../../../../assets/lotties/writing.json';
import { ChatHistory, Message } from "../../../../models/ChatHistory";
import CloseIcon from '@mui/icons-material/Close';

import './CommentsThread.scss';

type MessageItem = {
    name: string,
    messageMarkdown: string,
    timestamp: Date | null
};

type Props = {
    applicationId: string,
    blockId: string,
    index: number,
    question: string,
    resolvedComments: { blockId: string, index: number }[],
    onResolve: () => void;
    onClose: () => void;
};

const templateId = "f0762bff-11f4-4d2f-8620-14a4dc7175ab";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

export const CommentsThread: React.FC<Props> = (props) => {
    const { applicationId, blockId, index, question, resolvedComments, onResolve, onClose } = props;

    const [userInput, setUserInput] = useState("");
    const [chatHistory, setChatHistory] = useState<ChatHistory>();
    const [pending, setPending] = useState<boolean>();

    const bottomRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const fetchChatHistory = async () => {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/chat/${applicationId}/${blockId}/${index}`
            );

            if (!response.ok) {
                throw new Error(await response.text());
            }

            const history = (await response.json()) as ChatHistory;
            setChatHistory(history);
        }

        fetchChatHistory();
    }, [applicationId, blockId, index]);

    useEffect(() => {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [chatHistory])

    const handleSendMessage = async () => {
        const _userInput = userInput;
        setUserInput("");
        setPending(true);

        axios.patch<Message>(`${process.env.REACT_APP_API_URL}/chat/${applicationId}/${templateId}/${blockId}/${index}`, { userMessage: _userInput })
            .then((response) => {
                setChatHistory({
                    ...chatHistory,
                    messages: [...chatHistory?.messages ?? [], response.data]
                });

                if (response.data.assistantMessage.includes('Thank you for your cooperation in providing the answers!')) {
                    onResolve();
                }
            })
            .finally(() => {
                setPending(false);
            });
    };

    const dataSource: MessageItem[] = [{
        name: "Growceanu",
        timestamp: null,
        messageMarkdown: question,
    }];

    chatHistory?.messages.forEach((m) => {
        dataSource.push({
            name: "Me",
            timestamp: m.timestamp,
            messageMarkdown: m.userMessage,
        });
        dataSource.push({
            name: "Growceanu",
            timestamp: m.timestamp,
            messageMarkdown: m.assistantMessage,
        });
    });

    const isResolved = resolvedComments.some(item => item.blockId === blockId && item.index === index);

    return (
        <div className="chat-container">
            <div className="message fixed">
                <div className="avatar">
                    <Avatar name={"Growceanu"} maxInitials={2} round size="24" />
                </div>
                <div className="message-details">
                    <span className="message-name">{"Growceanu"}</span>
                    <div className="message-content" dangerouslySetInnerHTML={{ __html: marked.parse("*Could you please provide the necessary details or clarify this point? Your input will help finalize the investment memo. Thank you!*") as string }}></div>
                </div>
                <div onClick={onClose}>
                    <CloseIcon sx={{ fontSize: '14px', color: '#6d6e71', cursor: 'pointer' }} />
                </div>
            </div>
            <div className="messages">
                {dataSource.map((message) => {
                    const messageHtml: string = marked.parse(message.messageMarkdown) as string;
                    return (
                        <div className="message" key={'message-' + message.timestamp + message.name}>
                            <div className="avatar">
                                <Avatar name={message.name} maxInitials={2} round size="24" />
                            </div>
                            <div className="message-details">
                                <span className="message-name">{message.name}</span>
                                <div className="message-content" dangerouslySetInnerHTML={{ __html: messageHtml }}></div>
                                {message.timestamp &&
                                    <span className="message-time">{format(message.timestamp, "EEEE, MMMM do, yyyy - h:mm:ss a")}</span>
                                }
                            </div>
                        </div>
                    );
                })}
                <div ref={bottomRef} />
            </div>

            {pending &&
                <Lottie options={defaultOptions}
                    height={40}
                    width={40}
                    isStopped={false}
                    isPaused={false} />
            }

            <div className="message-input">
                <Box display={"flex"} sx={{ margin: "4px -40px 16px 18px" }}>
                    <textarea
                        placeholder="Type here..."
                        value={userInput}
                        onChange={(e) => setUserInput(e.target.value)}
                        style={{
                            border: "1px solid #6d6e7120",
                            overflow: "hidden",
                            width: "100%",
                            padding: "8px 56px 8px 8px",
                        }}
                        disabled={isResolved}
                        rows={3}
                    />
                    <Box sx={{ position: "relative", right: "48px", display: "flex" }}>
                        <Button
                            icon={{
                                component: <SendIcon />,
                            }}
                            color="#6d6e71"
                            onClick={handleSendMessage}
                            type="transparent"
                            title="Send"
                            disabled={isResolved}
                        />
                    </Box>
                </Box>
            </div>
        </div>
    );
};